import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { linkBackend } from '../../lib/linkBackend';
import Swal from 'sweetalert2';

//Imagenes
import errorImg from '../../img/formulario/error.png';
import cerrarImg from '../../img/formulario/cerrar.png';

const AgregarAdministrador = (props) => {
    const [ administradores, setAdministradores ] = useState([]);
    const { setAgregarAdministrador } = props;




    //Llamado a la api para traer los usuarios.
    useEffect(() => {
        fetch( linkBackend('/api/login/accesos'),{
            headers: {
                Authorization: localStorage.getItem('Token')
            }
        })
        .then(response => response.json())
        .then((data) => {
            setAdministradores(data);
        },(error) => {
            console.error("Error al recibir los datos de la API: ", error);
        })
    }, []);

    //Generamos un array con solamente los nombres de los usuarios.
    let nombresUsuario = administradores.map(usuario => {
        return usuario.usuario;
    })


    return (
        <div className='formulario__div--container'>
            <div className='formulario___div--container--informacion'>
                <h3 className='formulario__h3--titulo'>Agregar administrador</h3>
                <button className='formulario__button--cerrar' onClick={() => setAgregarAdministrador(false)}><img src={cerrarImg} alt='cerrar'/></button>
                <>
                    <Formik
                        initialValues={{
                            usuario: '',
                            contrasena: '',
                            confirmarContrasena: ''
                        }}

                        validate={(valores) => {
                            let errores = {};

                            // Validacion usuario
                            if(!valores.usuario){
                                errores.usuario = 'Por favor ingresa el nombre de usuario'
                            }else if(nombresUsuario.includes(valores.usuario)){
                                errores.usuario = 'Este nombre de usuario ya existe'
                            }

                            // Validacion contraseña
                            if(!valores.contrasena){
                                errores.contrasena = 'Por favor ingresa una contrasena'
                            }

                            if(!valores.confirmarContrasena){
                                errores.confirmarContrasena = 'Por favor ingresa una contrasena'
                            }else if(valores.contrasena !== valores.confirmarContrasena){
                                errores.confirmarContrasena = 'Las contraseñas no coinciden'
                            }

                            return errores;
                        }}
                        onSubmit={(valores, {resetForm}) => {
                            try{
                                let config = {
                                    method: 'POST',
                                    headers: {
                                        'accept': 'application/json',
                                        'Content-Type': 'application/json',
                                        'Authorization': localStorage.getItem('Token')
                                    },body: JSON.stringify({
                                        usuario: valores.usuario,
                                        contrasena: valores.contrasena,
                                    })
                                }

                                fetch(linkBackend("/api/login/registro"), config)
                                .then(response => response.json())
                                .then((data) => {
                                    if(data.mensaje === "Usuario registrado correctamente"){
                                        Swal.fire(
                                            'Hecho',
                                            'Usuario registrado con exito',
                                            'success'
                                        )
                                        resetForm();
                                    }else if(data.mensaje === "Error al registrar usuario"){
                                        Swal.fire(
                                            'Error',
                                            'Error al registrar usuario',
                                            'error'
                                        )
                                    }
                                })
                            }catch (error){
                                console.error("Error al ejecutar el fetch.")
                            }
                        }}
                    >
                        {( {errors } ) => (
                            <Form>
                                <div className='paquete__div--field-label'>
                                    <label htmlFor="usuario">Usuario</label>
                                    <Field
                                        type="text"
                                        id="usuario"
                                        name="usuario"
                                        className="formulario__field"
                                        placeholder="Ingrese un usuario"
                                    />
                                    <ErrorMessage name="usuario" component={() => (<div className="formulario__error"><img src={errorImg} alt='error'/>{errors.usuario}<img src={errorImg} alt='error'/></div>)}/>
                                </div>
                                <div className='paquete__div--field-label'>
                                    <label htmlFor="contrasena">Contraseña</label>
                                    <Field
                                        type="password"
                                        id="contrasena"
                                        name="contrasena"
                                        className="formulario__field"
                                        placeholder="Ingrese una contraseña"
                                    />
                                    <ErrorMessage name="contrasena" component={() => (<div className="formulario__error"><img src={errorImg} alt='error'/>{errors.contrasena}<img src={errorImg} alt='error'/></div>)}/>
                                </div>
                                <div className='paquete__div--field-label'>
                                    <label htmlFor="confirmarContrasena">Confirmar contraseña</label>
                                    <Field
                                        type="password"
                                        id="confirmarContrasena"
                                        name="confirmarContrasena"
                                        className="formulario__field"
                                        placeholder="Ingrese de nuevo la contraseña"
                                    />
                                    <ErrorMessage name="confirmarContrasena" component={() => (<div className="formulario__error"><img src={errorImg} alt='error'/>{errors.confirmarContrasena}<img src={errorImg} alt='error'/></div>)}/>
                                </div>


                                <div className='formulario__div--container-button'>
                                    <button type="submit">Crear</button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            </div>
        </div>
    )
}

export default AgregarAdministrador