import React, { useState } from 'react'
import ActualizarContrasenaAdministrador from './ActualizarContrasenaAdministrador';
import { linkBackend } from '../../lib/linkBackend';
import Swal from 'sweetalert2';
import { borrarAcceso } from '../../lib/funcionesGlobales';

const AdministradorTarjeta = (props) => {
    const { _id, usuario } = props.administrador;
    const [ componenteActualizarContrasena, setComponenteActualizarContrasena ] = useState(false);

    return (
        <>
            <div className='administrador-tarjeta__div--container'>
                <p className='administrado-tarjeta__p--usuario'>{usuario}</p>
                <div className='administrador-tarjeta__div--botones-tarjeta'>
                    <button className='administrador-tarjeta__button-cambiar-contrasena' onClick={() => setComponenteActualizarContrasena(true)}>Cambiar contraseña</button>
                    <button className='administrador-tarjeta__button-eliminar' onClick={() =>  borrarAcceso(_id, "¿Quieres borrar este usuario?", linkBackend("/api/login/eliminar"), "Usuario borrado con exito", "Error al borrar usuario", Swal)}>Eliminar</button>
                </div>
            </div>
            {componenteActualizarContrasena ? (<ActualizarContrasenaAdministrador setComponenteActualizarContrasena={setComponenteActualizarContrasena} administrador={props.administrador}/>):("")}
        </>
    )
}

export default AdministradorTarjeta