import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import NavBarMovil from './NavBarMovil';
import NavBarDesktop from './NavBarDesktop';


const NavBar = (props) => {
    const [pantallaChica, setPantallaChica] = useState(false);
    const { setUsuario, usuario } = props;


    const navegar = useNavigate();

    const cerrarSesion = () => {
        localStorage.removeItem('Token');
        setUsuario(null);
        navegar('/');
    }



    //Estado useEfect que recoge el tamaño de la pantalla y lo almacena para trabajar con el.
    useEffect(() => {
        if (getWindowSize().innerWidth > 999) {
            setPantallaChica(false);
        } else if (getWindowSize().innerWidth <= 999) {
            setPantallaChica(true);
        }
        function handleWindowResize() {
       //Cada vez que el escuchador se ejecuta el va a ejecutar esta funcion lo que hace es cuando obtiene el tamaño de la pantalla el ejecuta el if que comprueba su tamaño y lo que hara es mostrar una barra de navegacion con opciones clasicas para pantallas grande y mostrara una barra de navegacion tipo menu hamburguesa cuando este pequeña la pantalla.
        if (getWindowSize().innerWidth > 800) {
                setPantallaChica(false);
        } else if (getWindowSize().innerWidth <= 800) {
                setPantallaChica(true);
            }
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, [getWindowSize]);



    return (
        <>
            <div className='nav-bar__div--container'>
                {pantallaChica ? (<NavBarMovil usuario={usuario} cerrarSesion={cerrarSesion}/>):(<NavBarDesktop usuario={usuario} cerrarSesion={cerrarSesion}/>)}
            </div>
            <Outlet/>
        </>
    )
}

export default NavBar;



//Funcion que toma el tamaño de la pantalla en todo momento.
function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}


// <NavLink to='/home/usuarios'>
// <button>
//     Usuarios
// </button>
// </NavLink>
// <NavLink to='/home/administrador'>
// <button>
//     Administrador
// </button>
// </NavLink>
// <NavLink to='/home/metricas'>
// <button>
//     Metricas
// </button>
// </NavLink>
// <button onClick={() => cerrarSesion()}>Cerrar sesión</button>
// <p>{usuario.usuario}</p>