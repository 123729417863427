import React from 'react'

const TarjetaDatos = (props) => {
    const { datos, numeros, fondo } = props;


    return (
        <div className='tarjeta-datos__div--container' style={{background: fondo}}>
            <p className='tarjeta-datos__p--datos'>{datos}</p>
            <p className='tarjeta-datos__p--numeros'>{numeros}</p>
        </div>
    )
}

export default TarjetaDatos;