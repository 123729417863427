import React from 'react';
import ListaUsuarios from '../components/UsuariosAdmin/ListaUsuarios'

const UsuariosAdmin = () => {

    return (
        <div>
            <ListaUsuarios/>
        </div>
    )
}

export default UsuariosAdmin


