import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { linkBackend } from '../../lib/linkBackend';
import Swal from 'sweetalert2';

//Imagenes
import errorImg from '../../img/formulario/error.png';
import cerrarImg from '../../img/formulario/cerrar.png';

const ActualizarContrasenaUsuario = (props) => {
    const { setComponenteActualizarContrasena } = props;

    const { _id } = props.usuario;
    return (
        <div className='formulario__div--container'>
            <div className='formulario___div--container--informacion'>
                <h3 className='formulario__h3--titulo'>Cambiar contraseña</h3>
                <button className='formulario__button--cerrar' onClick={() => setComponenteActualizarContrasena(false)}><img src={cerrarImg} alt='cerrar'/></button>
                <Formik
                    initialValues={{
                        contrasena: "",
                        contrasenaConfirmacion: ""
                    }}
                    validate={(valores) => {
                        let errores = {};

                        if(!valores.contrasena){
                            errores.contrasena = 'Por favor ingresa una constraseña'
                        }

                        if(valores.contrasena !== valores.contrasenaConfirmacion){
                            errores.contrasenaConfirmacion = 'Las contraseñas no coinciden'
                        }

                        if(!valores.contrasenaConfirmacion){
                            errores.contrasenaConfirmacion = 'Por favor ingresa de nuevo la contraseña'
                        }

                        return errores;
                    }}
                    onSubmit={(valores) => {
                            try{
                                let config = {
                                    method: 'PUT',
                                    headers: {
                                        'accept': 'application/json',
                                        'Content-Type': 'application/json',
                                        'Authorization': localStorage.getItem('Token')
                                    },body: JSON.stringify({
                                        _id: _id,
                                        contrasena: valores.contrasena
                                    })
                                }

                                fetch(linkBackend("/api/login-usuarios/cambio-contrasena"), config)
                                .then(response => response.json())
                                .then((data) => {
                                    if(data.mensaje === "Se cambio la contraseña con exito"){
                                        Swal.fire(
                                            'Hecho',
                                            'Usuario registrado con exito',
                                            'success'
                                        )
                                        setComponenteActualizarContrasena(false);
                                    }else if(data.mensaje === "Error al cambiar contraseña" || data.mensaje === "Error al encriptar contraseña"){
                                        Swal.fire(
                                            'Error',
                                            'Error al registrar usuario',
                                            'error'
                                        )
                                    }
                                })
                            }catch (error){
                                console.error("Error al ejecutar el fetch.")
                            }
                    }}
                >
                    {( { errors } ) => (
                        <Form>
                            <div className='actualizar-contrasena__div--container-formulario'>
                                <div className='paquete__div--field-label'>
                                    <label htmlFor="contrasena">Contraseña</label>
                                    <Field
                                        type="password"
                                        id="contrasena"
                                        name="contrasena"
                                        className="formulario__field"
                                        placeholder="Introducir contraseña"
                                    />
                                    <ErrorMessage name="contrasena" component={() => (
                                            <div className="formulario__error">
                                                <img src={errorImg} alt='error'/>
                                                    {errors.contrasena}
                                                <img src={errorImg} alt='error'/>
                                            </div>)} />
                                </div>
                                <div className='paquete__div--field-label'>
                                    <label htmlFor="contrasenaConfirmacion">Confirmar contraseña</label>
                                    <Field
                                        type="password"
                                        id="contrasenaConfirmacion"
                                        name="contrasenaConfirmacion"
                                        className="formulario__field"
                                        placeholder="Introducir contraseña"
                                    />
                                    <ErrorMessage name="contrasenaConfirmacion" component={() => (
                                            <div className="formulario__error">
                                                <img src={errorImg} alt='error'/>
                                                    {errors.contrasenaConfirmacion}
                                                <img src={errorImg} alt='error'/>
                                            </div>)} />
                                    </div>
                                <div className='formulario__div--container-button actualizar-contrasena__div--container-button'>
                                    <button type="submit">Actualizar contraseña</button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default ActualizarContrasenaUsuario