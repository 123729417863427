import React, { useEffect, useState } from "react";
import UsuarioTarjetaMovil from "./UsuarioTarjetaMovil";
import UsuarioTarjetaDesktop from "./UsuarioTarjetaDesktop";



const UsuarioTarjeta = (props) => {
    const [pantallaChica, setPantallaChica] = useState(false);

    const {
        _id,
        usuario,
        nombre,
        email,
        tipo,
        vencimiento,
        puntosSeguimiento,
        cuentaMasiva,
        cuentaActiva,
    } = props.usuario;



    //Estado useEfect que recoge el tamaño de la pantalla y lo almacena para trabajar con el.
    useEffect(() => {
        if (getWindowSize().innerWidth > 999) {
            setPantallaChica(false);
        } else if (getWindowSize().innerWidth <= 999) {
            setPantallaChica(true);
        }
        function handleWindowResize() {
       //Cada vez que el escuchador se ejecuta el va a ejecutar esta funcion lo que hace es cuando obtiene el tamaño de la pantalla el ejecuta el if que comprueba su tamaño y lo que hara es mostrar una barra de navegacion con opciones clasicas para pantallas grande y mostrara una barra de navegacion tipo menu hamburguesa cuando este pequeña la pantalla.
        if (getWindowSize().innerWidth > 800) {
                setPantallaChica(false);
        } else if (getWindowSize().innerWidth <= 800) {
                setPantallaChica(true);
            }
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, [getWindowSize]);

    return (
        <>
            {pantallaChica ? (<UsuarioTarjetaMovil _id={_id} usuario={usuario} nombre={nombre} email={email} tipo={tipo} vencimiento={vencimiento} puntosSeguimiento={puntosSeguimiento} cuentaMasiva={cuentaMasiva} cuentaActiva={cuentaActiva}/>):(<UsuarioTarjetaDesktop _id={_id} usuario={usuario} nombre={nombre} email={email} tipo={tipo} vencimiento={vencimiento} puntosSeguimiento={puntosSeguimiento} cuentaMasiva={cuentaMasiva} cuentaActiva={cuentaActiva}/>) }
        </>
    );
};

export default UsuarioTarjeta;


//Funcion que toma el tamaño de la pantalla en todo momento.
function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

