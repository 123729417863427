//Apartado para manejar las funciones globales.


//Funcion de borrado de datos.
const borrarAcceso = (_id, pregunta, url, mensajeconfirmacion, mensajeError, Swal) => {
    Swal.fire({
        title: pregunta,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Eliminar',
        denyButtonText: `No eliminar`,
        }).then((result) => {
            if (result.isConfirmed) {
                try{
                    let config = {
                        method: 'DELETE',
                        headers: {
                            'accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': localStorage.getItem('Token')
                        },body: JSON.stringify({
                            _id: _id,
                        })
                    }

                    fetch(url, config)
                    .then(response => response.json())
                    .then((data) => {
                        if(data.mensaje === mensajeconfirmacion){
                            Swal.fire(
                                'Hecho',
                                mensajeconfirmacion,
                                'success'
                            )
                        }else if(data.mensaje === mensajeError){
                            Swal.fire(
                                'Error',
                                mensajeError,
                                'error'
                            )
                        }
                    })
                }catch (error){
                    console.error("Error al ejecutar el fetch.")
                }
            } else if (result.isDenied) {
                Swal.fire('No se eliminó el acceso ', '', 'info')
            }
        })
}


export { borrarAcceso };