import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { linkBackend } from '../../lib/linkBackend';
import Swal from 'sweetalert2';


//Imagenes
import errorImg from '../../img/formulario/error.png';
import cerrarImg from '../../img/formulario/cerrar.png';

const AgregarUsuario = (props) => {
    const [formularioEnviado, setFormularioEnviado] = useState(false);
    const [ usuarios, setUsuarios ] = useState([]);
    const { setAgregarUsuarioEstado } = props;


    const arrayTipoUsuario = [
        "Estudiante",
        "Padre de familia",
        "Docente",
        "Establecimiento Educativo",
        "Otro"
    ]


    //Funcion que controla cuando un formulario se envio para actualizar el llamado a la api
    const formularioEnviadoAlaApi = () => {
        if(formularioEnviado){
            setFormularioEnviado(false);
        }else if(!formularioEnviado){
            setFormularioEnviado(true);
        }
    }

    //Llamado a la api para traer los usuarios.
    useEffect(() => {
        fetch( linkBackend('/api/login-usuarios/usuarios'),{
            headers: {
                Authorization: localStorage.getItem('Token')
            }
        })
        .then(response => response.json())
        .then((data) => {
            setUsuarios(data);
        },(error) => {
            console.error("Error al recibir los datos de la API: ", error);
        })
    }, [formularioEnviado]);

    //Generamos un array con solamente los nombres de los usuarios.
    let nombresUsuario = usuarios.map(usuario => {
        return usuario.usuario;
    })


    return (
        <div className='formulario__div--container'>
            <div className='formulario___div--container--informacion'>
                <h3 className='formulario__h3--titulo'>Agregar usuario</h3>
                <button className='formulario__button--cerrar' onClick={() => setAgregarUsuarioEstado(false)}><img src={cerrarImg} alt='cerrar'/></button>
                <>
                    <Formik
                        initialValues={{
                            nombre: '',
                            usuario: '',
                            correo: '',
                            tipo: '',
                            vencimiento: '',
                            contrasena: '',
                            confirmarContrasena: ''
                        }}

                        validate={(valores) => {
                            let errores = {};

                            // Validacion nombre
                            if(!valores.nombre){
                                errores.nombre = 'Por favor ingresa un nombre'
                            } else if(!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(valores.nombre)){
                                errores.nombre = 'El nombre solo puede contener letras y espacios'
                            }

                            // Validacion usuario
                            if(!valores.usuario){
                                errores.usuario = 'Por favor ingresa el nombre de usuario'
                            }else if(nombresUsuario.includes(valores.usuario)){
                                errores.usuario = 'Este nombre de usuario ya existe'
                            }

                            // Validacion contraseña
                            if(!valores.contrasena){
                                errores.contrasena = 'Por favor ingresa una contraseña'
                            }

                            if(!valores.confirmarContrasena){
                                errores.confirmarContrasena = 'Por favor ingresa una contrasena'
                            }else if(valores.contrasena !== valores.confirmarContrasena){
                                errores.confirmarContrasena = 'Las contraseñas no coinciden'
                            }

                            // Validacion correo
                            if(!valores.correo){
                                errores.correo = 'Por favor ingresa un correo electronico'
                            } else if(!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(valores.correo)){
                                errores.correo = 'El correo solo puede contener letras, numeros, puntos, guiones y guion bajo.'
                            }

                            // Validacion tipo
                            if(!valores.tipo){
                                errores.tipo = 'Por favor ingresa el tipo de usuario'
                            }

                            if(!arrayTipoUsuario.includes(valores.tipo)){
                                errores.tipo = 'Opción no valida'
                            }

                            // Validacion vencimiento
                            if(!valores.vencimiento){
                                errores.vencimiento = 'Por favor ingresa una fecha de vencimiento'
                            }

                            return errores;
                        }}
                        onSubmit={(valores, {resetForm}) => {
                            try{
                                let config = {
                                    method: 'POST',
                                    headers: {
                                        'accept': 'application/json',
                                        'Content-Type': 'application/json',
                                        'Authorization': localStorage.getItem('Token')
                                    },body: JSON.stringify({
                                        usuario: valores.usuario,
                                        nombre: valores.nombre,
                                        contrasena: valores.contrasena,
                                        email: valores.correo,
                                        tipo: valores.tipo,
                                        vencimiento: valores.vencimiento,
                                    })
                                }

                                fetch(linkBackend("/api/login-usuarios/registro"), config)
                                .then(response => response.json())
                                .then((data) => {
                                    if(data.mensaje === "Usuario registrado correctamente"){
                                        Swal.fire(
                                            'Hecho',
                                            'Usuario registrado con exito',
                                            'success'
                                        )
                                        resetForm();
                                    }else if(data.mensaje === "Error al registrar usuario"){
                                        Swal.fire(
                                            'Error',
                                            'Error al registrar usuario',
                                            'error'
                                        )
                                    }
                                })
                            }catch (error){
                                console.error("Error al ejecutar el fetch.")
                            }
                        }}
                    >
                        {( {errors, values} ) => (
                            <Form>
                                <div className='agregar-usuario__div--container-secciones'>
                                    <div className='agregar-usuario__div--seccion'>
                                        <div className='paquete__div--field-label'>
                                            <label htmlFor="nombre">Nombre</label>
                                            <Field
                                                type="text"
                                                id="nombre"
                                                name="nombre"
                                                className="formulario__field"
                                                placeholder="Ingrese un nombre"
                                            />
                                            <ErrorMessage name="nombre" component={() => (<div className="formulario__error"><img src={errorImg} alt='error'/>{errors.nombre}<img src={errorImg} alt='error'/></div>)}/>
                                        </div>
                                        <div className='paquete__div--field-label'>
                                            <label htmlFor="usuario">Usuario</label>
                                            <Field
                                                type="text"
                                                id="usuario"
                                                name="usuario"
                                                className="formulario__field"
                                                placeholder="Ingrese un usuario"
                                            />
                                            <ErrorMessage name="usuario" component={() => (
                                            <div className="formulario__error">
                                                <img src={errorImg} alt='error'/>
                                                    {errors.usuario}
                                                <img src={errorImg} alt='error'/>
                                            </div>)} />
                                        </div>
                                        <div className='paquete__div--field-label'>
                                            <label htmlFor="correo">Correo</label>
                                            <Field
                                                type="email"
                                                id="correo"
                                                name="correo"
                                                className="formulario__field"
                                                placeholder="Ingrese un correo electronico"
                                            />
                                            <ErrorMessage name="correo" component={() => (
                                                <div className="formulario__error">
                                                    <img src={errorImg} alt='error'/>
                                                        {errors.correo}
                                                    <img src={errorImg} alt='error'/>
                                                </div>)} />
                                        </div>
                                        <div className='paquete__div--field-label'>
                                            <div>
                                                <div className='paquete__div--contenedor-label-datalist'>
                                                    <label>Tipo de usuario:</label>
                                                </div>
                                                <label htmlFor="dataList" >
                                                    <Field
                                                        type="text"
                                                        list="datos-lista"
                                                        name="tipo"
                                                        className="formulario__field2"
                                                        id="tipo"
                                                    />
                                                </label>
                                                <datalist id="datos-lista">
                                                    {arrayTipoUsuario.map(tipo => {
                                                        return <option value={tipo}>{tipo}</option>
                                                    })}
                                                </datalist>
                                                <ErrorMessage name="tipo" component={() => (
                                                <div className="formulario__error">
                                                    <img src={errorImg} alt='error'/>
                                                        {errors.tipo}
                                                    <img src={errorImg} alt='error'/>
                                                </div>)} />
                                            </div>
                                        </div>
                                        <div className='paquete__div--field-label'>
                                            <label htmlFor="vencimiento">Fecha de vencimiento</label>
                                            <Field
                                                type="date"
                                                id="vencimiento"
                                                name="vencimiento"
                                                className="formulario__field2"
                                            />
                                            <ErrorMessage name="vencimiento" component={() => (
                                                <div className="formulario__error">
                                                    <img src={errorImg} alt='error'/>
                                                        {errors.vencimiento}
                                                    <img src={errorImg} alt='error'/>
                                                </div>)} />
                                            <p>{values.vencimiento}</p>
                                        </div>
                                    </div>
                                    <div className='agregar-usuario__div--seccion'>
                                        <div className='paquete__div--field-label'>
                                            <label htmlFor="contrasena">Contraseña</label>
                                            <Field
                                                type="password"
                                                id="contrasena"
                                                name="contrasena"
                                                className="formulario__field"
                                                placeholder="Ingrese una contraseña"
                                            />
                                            <ErrorMessage name="contrasena" component={() => (
                                            <div className="formulario__error">
                                                <img src={errorImg} alt='error'/>
                                                    {errors.contrasena}
                                                <img src={errorImg} alt='error'/>
                                            </div>)} />
                                        </div>
                                        <div className='paquete__div--field-label'>
                                            <label htmlFor="confirmarContrasena">Confirmar contraseña</label>
                                            <Field
                                                type="password"
                                                id="confirmarContrasena"
                                                name="confirmarContrasena"
                                                className="formulario__field"
                                                placeholder="Ingrese de nuevo la contraseña"
                                            />
                                            <ErrorMessage name="confirmarContrasena" component={() => (
                                            <div className="formulario__error">
                                                <img src={errorImg} alt='error'/>
                                                    {errors.confirmarContrasena}
                                                <img src={errorImg} alt='error'/>
                                            </div>)} />
                                        </div>

                                        <div className='formulario__div--container-button'>
                                            <button type="submit">Crear</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            </div>
        </div>
    )
}

export default AgregarUsuario