import React, { useEffect, useState } from 'react';
import { linkBackend } from '../lib/linkBackend';
import TarjetaDatos from '../components/Metricas/TarjetaDatos';
import TarjetaJuegos from '../components/Metricas/TarjetaJuegos';


const Metricas = () => {
    const [ carga, setCarga ] = useState("");
    const [ metricas, setMetricas ] = useState("");
    const [ carga2, setCarga2 ] = useState("");
    const [ usuarios , setUsuarios ] = useState("");
    const [ usuariosActivos, setUsuariosActivos ] = useState(0);
    const [ puntosTotales, setPuntosTotales ] = useState(0);





    useEffect(() => {
        fetch(linkBackend('/api/metricas'),{
            headers: {
                Authorization: localStorage.getItem('Token')
            }
        })
        .then(response => response.json())
        .then((data) => {
            setCarga(true);
            setMetricas(data);
        },(error) => {
            setCarga(true);
        })
    }, []);

    useEffect(() => {
        fetch(linkBackend('/api/login-usuarios/usuarios'),{
            headers: {
                Authorization: localStorage.getItem('Token')
            }
        })
        .then(response => response.json())
        .then((data) => {
            setCarga2(true);
            setUsuarios(data);
        },(error) => {
            setCarga(true);
        })
    }, []);

    useEffect(() => {
        if(carga2){
            let usuariosFiltrados = usuarios.filter(usuario => usuario.cuentaActiva);

            let puntosAcumulados = 0;

            for (let i = 0; i < usuarios.length; i++) {
                puntosAcumulados = puntosAcumulados + usuarios[i].puntosSeguimiento
            }

            setPuntosTotales(puntosAcumulados);
            setUsuariosActivos(usuariosFiltrados.length);
        }
    }, [usuarios]);

    return (
        <div className='metricas__div--container'>
            <h2>Metricas generales</h2>
            <div className='metricas__div--grid--generales'>
                {carga ? (<TarjetaDatos datos="Ingresos al aplicativo" numeros={metricas.metricasGenerales[0].numeroIngresos} fondo="linear-gradient(270deg, #FFA500 25.87%, #FFA500 31.73%, #F1F1F1 38.49%)"/>):(<TarjetaDatos datos="Cargando..." numeros="Cargando..."/>)}

                {carga ? (<TarjetaDatos datos="Tarjetas reclamadas" numeros={metricas.metricasGenerales[0].tarjetasReclamadas} fondo="linear-gradient(270deg, #00CED1 25.87%, #5FEAED 31.73%, #F1F1F1 38.49%)"/>):(<TarjetaDatos datos="Cargando..." numeros="Cargando..."/>)}

                {carga ? (<TarjetaDatos datos="Cuentas totales" numeros={usuarios.length} fondo="linear-gradient(270deg, #6C69FF 25.87%, #9593FF 31.73%, #F1F1F1 38.49%)"/>):(<TarjetaDatos datos="Cargando..." numeros="Cargando..."/>)}

                {carga ? (<TarjetaDatos datos="Cuentas activas" numeros={usuariosActivos} fondo="linear-gradient(270deg, #3DEA00 25.87%, #8EFF66 31.73%, #F1F1F1 38.49%)"/>):(<TarjetaDatos datos="Cargando..." numeros="Cargando..."/>)}

                {carga ? (<TarjetaDatos datos="Puntos acumulados" numeros={puntosTotales} fondo="linear-gradient(270deg, #EB00FF 25.87%, #EB00FF 31.73%, #F1F1F1 38.49%)"/>):(<TarjetaDatos datos="Cargando..." numeros="Cargando..."/>)}

            </div>

            <h2>Juegos</h2>
            <div className='metricas__div--grid--generales'>
                {carga ? (<TarjetaJuegos abierto={metricas.metricasJuegos[0].juegoAbierto} nombre={metricas.metricasJuegos[0].nombreJuego} terminado={metricas.metricasJuegos[0].juegoTerminado} derecha="linear-gradient(270deg, #FFA500 75%, #FFA500 86.61%, #F1F1F1 100%)" izquierda="linear-gradient(90deg, #FFA500 75%, #FFA500 86.61%, #F1F1F1 100%)"/>):(<TarjetaDatos datos="Cargando..." numeros="Cargando..."/>)}

                {carga ? (<TarjetaJuegos abierto={metricas.metricasJuegos[0].juegoAbierto} nombre={metricas.metricasJuegos[0].nombreJuego} terminado={metricas.metricasJuegos[0].juegoTerminado} derecha="linear-gradient(270deg, #FFA500 75%, #FFA500 86.61%, #F1F1F1 100%)" izquierda="linear-gradient(90deg, #FFA500 75%, #FFA500 86.61%, #F1F1F1 100%)"/>):(<TarjetaDatos datos="Cargando..." numeros="Cargando..."/>)}

                {carga ? (<TarjetaJuegos abierto={metricas.metricasJuegos[0].juegoAbierto} nombre={metricas.metricasJuegos[0].nombreJuego} terminado={metricas.metricasJuegos[0].juegoTerminado} derecha="linear-gradient(270deg, #FFA500 75%, #FFA500 86.61%, #F1F1F1 100%)" izquierda="linear-gradient(90deg, #FFA500 75%, #FFA500 86.61%, #F1F1F1 100%)"/>):(<TarjetaDatos datos="Cargando..." numeros="Cargando..."/>)}

                {carga ? (<TarjetaJuegos abierto={metricas.metricasJuegos[0].juegoAbierto} nombre={metricas.metricasJuegos[0].nombreJuego} terminado={metricas.metricasJuegos[0].juegoTerminado} derecha="linear-gradient(270deg, #FFA500 75%, #FFA500 86.61%, #F1F1F1 100%)" izquierda="linear-gradient(90deg, #FFA500 75%, #FFA500 86.61%, #F1F1F1 100%)"/>):(<TarjetaDatos datos="Cargando..." numeros="Cargando..."/>)}

                {carga ? (<TarjetaJuegos abierto={metricas.metricasJuegos[0].juegoAbierto} nombre={metricas.metricasJuegos[0].nombreJuego} terminado={metricas.metricasJuegos[0].juegoTerminado} derecha="linear-gradient(270deg, #FFA500 75%, #FFA500 86.61%, #F1F1F1 100%)" izquierda="linear-gradient(90deg, #FFA500 75%, #FFA500 86.61%, #F1F1F1 100%)"/>):(<TarjetaDatos datos="Cargando..." numeros="Cargando..."/>)}

            </div>
        </div>
    )
}

export default Metricas