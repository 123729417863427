import React from 'react';
import Atropos from 'atropos/react';
import 'atropos/css'

//Imagenes
import imagen1 from  '../img/diseno-3d-1.png';
import imagen2 from  '../img/diseno-3d-2.png';
import imagen3 from  '../img/diseno-3d-3.png';
import imagen4 from  '../img/diseno-3d-4.png';
import imagen5 from  '../img/diseno-3d-5.png';


const Ensayos = () => {
    return (
        <div className='container-3d'>
            <div class="container">
                <Atropos className='atropos-component'>
                    <div className='atropos'>
                        <div className='atropos-scale'>
                            <div className='atropos-rotate'>
                                <div className='atropos-inner'>
                                    <img className="atropos-spacer" data-atropos-offset="-2" data-atropos-opacity="0.3;1" src={imagen1} alt='imagen-1'/>
                                    <img data-atropos-offset="-2" className='atropos-3d atropos-3d-1' src={imagen1} alt='imagen-1'/>
                                    <img data-atropos-offset="-1" className='atropos-3d atropos-3d-2' src={imagen2} alt='imagen-2'/>
                                    <img data-atropos-offset="0" className='atropos-3d atropos-3d-3' src={imagen3} alt='imagen-3'/>
                                    <img data-atropos-offset="1" className='atropos-3d atropos-3d-4' src={imagen4} alt='imagen-4'/>
                                    <img data-atropos-offset="2" className='atropos-3d atropos-3d-5' src={imagen5} alt='imagen-5'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Atropos>
            </div>
        </div>
    )
}

export default Ensayos