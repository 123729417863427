import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { linkBackend } from '../lib/linkBackend';


//Imagenes
import logoGalileo from '../img/index/logo-mascota-galileo.png';
import logoGalileoPrincipal from '../img/index/logo-galileo.png';


const Index = (props) => {
    const [ errorAutenticacion, setErrorAutenticacion ] = useState(null);
    const [ cargandoInicio, setCargandoInicio ] = useState(false);


    const { usuario, setLoginIn, loginIn} = props;

    let navegar = useNavigate();


    //Definimos si en el estado errorAutenticacion hay un mensaje que despues de 4 segundos vuelva a ser null para que desaparezca de la pantalla.
    if(errorAutenticacion){
        setTimeout(() => {
            setErrorAutenticacion(null);
        }, 4000);
    }

    // useEffect(() => {
    //     if(cargando === true && errorAutenticacion === null){
    //         setMensajeCarga(<div id="contenedor">
    //             <div class="loader" id="loader">Loading...</div>
    //         </div>);
    //     }else if(errorAutenticacion){
    //         setMensajeCarga("");
    //         setCargando(false);
    //     }
    // }, [cargando, errorAutenticacion])


    return (
            <div className='index__div--container'>
                <div className='index__div--paquete-secciones'>
                    <div className='index__div--container-logo'>
                        <img src={logoGalileoPrincipal} alt='logo-principal-galileo'/>
                    </div>
                    <div>
                        <Formik
                            initialValues={{
                                usuario:"",
                                contrasena:""
                            }}

                            validate={
                                (valores) => {
                                    let errores = {};

                                    if(!valores.usuario){
                                        errores.usuario = "Introduzca un usuario valido";
                                    }

                                    if(!valores.contrasena){
                                        errores.contrasena = "Introduzca una contraseña correcta";
                                    }

                                    return errores;
                                }
                            }

                            onSubmit={
                                    async(valores) => {
                                        setCargandoInicio(true);
                                        try {
                                            let config = {
                                                method: 'POST',
                                                headers: {
                                                    'accept': 'application/json',
                                                    'Content-Type': 'application/json'
                                                },body: JSON.stringify({
                                                    usuario: valores.usuario,
                                                    contrasena: valores.contrasena
                                                })
                                            }
                                            let res = await fetch(linkBackend("/api/login/autenticacion"), config)
                                            let json = await res.json();
                                            let authorizationValue = res.headers.get('Authorization')

                                            if(json.mensaje === "Este Usuario no existe"){
                                                setErrorAutenticacion("Este Usuario no existe");
                                                setCargandoInicio(false);
                                            }else if(json.mensaje === "Usuario y contraseña incorrectos"){
                                                setErrorAutenticacion("Usuario y contraseña incorrectos");
                                                setCargandoInicio(false)
                                            }else if(json.mensaje === "Usuario autenticado correctamente"){


                                                //Verificar si hubo un acceso para que traiga el usuario al estado para acceder a la pagina.
                                                if(loginIn === false){
                                                    setLoginIn(true);
                                                }else if(loginIn === true){
                                                    setLoginIn(false);
                                                }

                                                //Guardando el token en la store del navegador.
                                                localStorage.setItem("Token", authorizationValue);

                                                setTimeout(() => {
                                                    navegar('/home/usuarios');
                                                }, 1000);

                                            }
                                        } catch (error) {
                                            console.error("Error con el login", error);
                                            setCargandoInicio(false);
                                        }
                                    }
                                }
                        >
                        {({errors}) => (
                            <Form>
                            {cargandoInicio ? (<div className='loader' id='loader'></div>):(
                                <>
                                    <div className='index__div--inputs'>
                                        <div className='index__div--usuario-container-input'>
                                            <Field
                                                type="text"
                                                id="usuario"
                                                name="usuario"
                                                placeholder="Introducir usuario"
                                                className="index__field--input index__field--input-usuario"
                                            />

                                        </div>
                                        <motion.div
                                            transition={{
                                                duration:1
                                            }}

                                            whileHover={{
                                                rotate: 360
                                            }}
                                        >
                                            <div className='index__div--logo-galileo'>
                                                <img className='index__img--logo-galileo' src={logoGalileo} alt='logo-galileo'/>
                                            </div>
                                        </motion.div>
                                        <div className='index__div--contrasena-container-input'>
                                            <Field
                                                type="password"
                                                id="contrasena"
                                                name="contrasena"
                                                placeholder="Introducir contraseña"
                                                className="index__field--input index__field--input-contrasena"
                                            />
                                        </div>
                                    </div>
                                    <div className='index__div--errores'>
                                        <ErrorMessage name="usuario" component={() => (<p className='index__error-message--errores' >{errors.usuario}</p>)}/>
                                        <ErrorMessage   name="contrasena" component={() => (<p className='index__error-message--errores' >{errors.contrasena}</p>)}/>
                                        {errorAutenticacion ? (<p className='index__error-message--errores'>{errorAutenticacion}</p>):("")}
                                    </div>


                                    <div className="index__div--container-button">
                                        <button type='submit'>Entrar</button>
                                    </div>
                                </>
                            )}
                            </Form>
                        )}
                        </Formik>
                    </div>
                </div>
            </div>
    )
}

export default Index;