import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';


//Imagenes
import hamburguesa from '../../img/Navbar/hamburguesa.png';
import cerrar from '../../img/Navbar/cerrar.png';
import usuarios from '../../img/Navbar/lista.png';
import administracion from '../../img/Navbar/administracion.png';
import metricas from '../../img/Navbar/metrica.png';
import cerrarSesionImg from '../../img/Navbar/cerrar-sesion.png';



const NavBarMovil = (props) => {
    const [ menuAbierto, setMenuAbierto ] = useState(true);
    const [ transicionBoton, setTransicionBoton] = useState("1");
    const [ aperturaMenu, setAperturaMenu ] = useState("0vh");
    const { usuario, cerrarSesion } = props;

    const procesarMenu = () => {
        if(menuAbierto){
            setMenuAbierto(false);
            setTransicionBoton("0.5");
            setAperturaMenu("calc(100vh - 80px)");
            setTimeout(() => {
                setTransicionBoton("1");
            }, 500);
        }else if(!menuAbierto){
            setMenuAbierto(true);
            setTransicionBoton("0.5");
            setAperturaMenu("0vh");
            setTimeout(() => {
                setTransicionBoton("1");
            }, 500);
        }
    }

    return (
        <>
            <div className='navbar-movil__div--container'>
                <div className='navbar-movil__div--fondo-animado'></div>
                <button onClick={() => procesarMenu()} className='navbar-movil__button--hamburguesa'>
                    {menuAbierto ? (<img style={{opacity: transicionBoton}} className='navbar-movil__img--hamburguesa' src={hamburguesa} alt='hamburguesa'/>):(<img style={{opacity: transicionBoton}} className='navbar-movil__img--cerrar' src={cerrar} alt='cerrar'/>)}
                </button>
                <p className='navbar-movil__p--usuario'>{usuario.usuario}</p>
            </div>
            <div style={{height: aperturaMenu}} className='navbar-movil__div--container-opciones'>
                <div className='navbar-movil__div--grid'>
                    <NavLink to='/home/usuarios'>
                        <button onClick={() => procesarMenu()} className='navbar-movil__button-opcion navbar-movil__button-opcion-naranja'>
                            <img src={usuarios} alt='usuarios'/>
                            Usuarios
                        </button>
                    </NavLink>
                    <NavLink to='/home/administrador'>
                        <button onClick={() => procesarMenu()}  className='navbar-movil__button-opcion navbar-movil__button-opcion-azul'>
                            <img src={administracion} alt='administracion'/>
                            Administrador
                        </button>
                    </NavLink>
                    <NavLink to='/home/metricas'>
                        <button onClick={() => procesarMenu()}  className='navbar-movil__button-opcion navbar-movil__button-opcion-azul'>
                            <img src={metricas} alt='metricas'/>
                            Metricas
                        </button>
                    </NavLink>
                    <button className='navbar-movil__button-opcion navbar-movil__button-opcion-naranja' onClick={() => cerrarSesion()}>
                        <img src={cerrarSesionImg} alt='cerrar sesión'/>
                        Cerrar sesión
                    </button>
                </div>
            </div>
        </>
    )
}

export default NavBarMovil