import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { linkBackend } from '../../lib/linkBackend';
import Swal from 'sweetalert2';


//Imagenes
import errorImg from '../../img/formulario/error.png';
import cerrarImg from '../../img/formulario/cerrar.png';

const ActualizarUsuario = (props) => {
    const { setComponenteActualizar } = props;

    const { _id, nombre, email, vencimiento} = props.usuario;


    return (
        <div className='formulario__div--container'>
			<div className='formulario___div--container--informacion'>
            	<h3 className='formulario__h3--titulo'>Actualizar usuario</h3>
				<button className='formulario__button--cerrar' onClick={() => setComponenteActualizar(false)}><img src={cerrarImg} alt='cerrar'/></button>
				<Formik
					initialValues={{
						nombre: nombre,
						email: email,
						vencimiento: vencimiento,

					}}
					validate={(valores) => {
						let errores = {};

						// Validacion nombre
						if(!valores.nombre){
							errores.nombre = 'Por favor ingresa un nombre'
						} else if(!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(valores.nombre)){
							errores.nombre = 'El nombre solo puede contener letras y espacios'
						}


						// Validacion correo
						if(!valores.email){
							errores.email = 'Por favor ingresa un correo electronico'
						} else if(!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(valores.email)){
							errores.email = 'El correo solo puede contener letras, numeros, puntos, guiones y guion bajo.'
						}

						if(!valores.vencimiento){
							errores.vencimiento = 'Por favor ingresa una fecha de vencimiento de la suscripción'
						}


						return errores;
					}}
					onSubmit={(valores) => {
							try{
								let config = {
									method: 'PUT',
									headers: {
										'accept': 'application/json',
										'Content-Type': 'application/json',
										'Authorization': localStorage.getItem('Token')
									},body: JSON.stringify({
										_id: _id,
										nombre: valores.nombre,
										email: valores.email,
										vencimiento: valores.vencimiento
									})
								}

								fetch(linkBackend("/api/login-usuarios"), config)
								.then(response => response.json())
								.then((data) => {
									if(data.mensaje === "Usuario actualizado correctamente"){
										Swal.fire(
											'Hecho',
											'Usuario registrado con exito',
											'success'
										)
										setComponenteActualizar(false);
									}else if(data.mensaje === "Error al actualizar usuario en base de datos" || data.mensaje === "Error al actualizar usuario."){
										Swal.fire(
											'Error',
											'Error al registrar usuario',
											'error'
										)
									}
								})
							}catch (error){
								console.error("Error al ejecutar el fetch.")
							}
					}}
				>
					{( {errors, values} ) => (
						<Form>
							<div className='actualizar-usuario__div--container-formulario'>
								<div className='actualizar-usuario__div--inputs-diamante'>
									<div className='paquete__div--field-label'>
										<label htmlFor="nombre">Nombre</label>
										<Field
											type="text"
											id="nombre"
											name="nombre"
											className="formulario__field"
											placeholder={nombre}
										/>
										<ErrorMessage name="nombre" component={() => (
                                            <div className="formulario__error">
                                                <img src={errorImg} alt='error'/>
                                                    {errors.nombre}
                                                <img src={errorImg} alt='error'/>
                                            </div>)} />
									</div>
									<div className='paquete__div--field-label'>
										<label htmlFor="email">Email</label>
										<Field
											type="text"
											id="email"
											name="email"
											className="formulario__field"
											placeholder={email}
										/>
										<ErrorMessage name="email" component={() => (
                                            <div className="formulario__error">
                                                <img src={errorImg} alt='error'/>
                                                    {errors.email}
                                                <img src={errorImg} alt='error'/>
                                            </div>)} />
									</div>
								</div>

								<div className='paquete__div--field-label'>
									<label htmlFor="vencimiento">Fecha de vencimiento</label>
									<Field
										type="date"
										id="vencimiento"
										name="vencimiento"
									/>
									<ErrorMessage name="vencimiento" component={() => (
                                            <div className="formulario__error">
                                                <img src={errorImg} alt='error'/>
                                                    {errors.vencimiento}
                                                <img src={errorImg} alt='error'/>
                                            </div>)} />
									{values.vencimiento ? (<p>{values.vencimiento}</p>):(<p>{vencimiento}</p>)}
								</div>

								<div className='formulario__div--container-button'>
									<button type="submit">Actualizar</button>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</div>
    )
}

export default ActualizarUsuario