import React, { useEffect, useState } from "react";

//Importacion librerias
import { linkBackend } from "../../lib/linkBackend";
import UsuarioTarjeta from "./UsuarioTarjeta/UsuarioTarjeta";
import AgregarUsuario from "./AgregarUsuario";

//Imagenes
import cofre from '../../img/usuarios/cofre.png';
import agregarUsuarioIcono from "../../img/usuarios/agregar-usuario.png";
import lupaBuscador from '../../img/usuarios/lupa-buscador.png';

const ListaUsuarios = () => {
  const [carga, setCarga] = useState("");
  const [usuarios, setUsuarios] = useState("");
  const [error, setError] = useState("");
  const [botonNext, setBotonNext] = useState(true);
  const [botonPrev, setBotonPrev] = useState(true);
  const [agregarUsuarioEstado, setAgregarUsuarioEstado] = useState(false);
  const [inputBuscador, setInputBuscador] = useState("");
  const [datosProcesados, setDatosProcesados] = useState([]);
  const [pantallaChica, setPantallaChica] = useState(false);


  //Estado useEfect que recoge el tamaño de la pantalla y lo almacena para trabajar con el.
  useEffect(() => {
      if (getWindowSize().innerWidth > 999) {
          setPantallaChica(false);
      } else if (getWindowSize().innerWidth <= 999) {
          setPantallaChica(true);
      }
      function handleWindowResize() {
     //Cada vez que el escuchador se ejecuta el va a ejecutar esta funcion lo que hace es cuando obtiene el tamaño de la pantalla el ejecuta el if que comprueba su tamaño y lo que hara es mostrar una barra de navegacion con opciones clasicas para pantallas grande y mostrara una barra de navegacion tipo menu hamburguesa cuando este pequeña la pantalla.
      if (getWindowSize().innerWidth > 800) {
              setPantallaChica(false);
      } else if (getWindowSize().innerWidth <= 800) {
              setPantallaChica(true);
          }
      }

      window.addEventListener("resize", handleWindowResize);

      return () => {
          window.removeEventListener("resize", handleWindowResize);
      };
  }, [getWindowSize]);



  //Datos por pagina a mostrar.
  const datosPorPagina = 15;

  useEffect(() => {
    let intervalo = setInterval(() => {
      fetch(linkBackend("/api/login-usuarios/usuarios"), {
        headers: {
          Authorization: localStorage.getItem("Token"),
        },
      })
        .then((response) => response.json())
        .then(
          (data) => {
            setCarga(true);
            setUsuarios(data);
          },
          (error) => {
            setCarga(true);
            setError(error);
          }
        );
    }, 1000);

    return () => {
      clearInterval(intervalo);
    };
  }, []);

  //Creamos el algoritmo para el buscador
  //La funcion que acogera lo que pongamos en el input y lo transformara en minusculas.
  const handleInputChangeBuscador = (event) => {
    setInputBuscador(event.target.value.toLowerCase());
  };

  //Creamos un map que mete los id en un array.
  const mapearDatos = () => {
    let datosMapeados = usuarios.map((usuario) => {
      return usuario.nombre;
    });

    return datosMapeados;
  };
  // let datosMapeados = usuarios.map(usuario => {
  //     return usuario.nombre
  // });

  //Tenemos la funcion que filtra los datos.
  function filterItems(inputBuscador, datos) {
    return datos.filter(function (el) {
      return el.toLowerCase().indexOf(inputBuscador.toLowerCase()) > -1;
    });
  }

  //Creamos la funcion que devuelve los datos completos de la busqueda.
  const encontrarDatos = (busqueda, datos) => {
    let datosMostrar = [];
    for (let i = 0; i < busqueda.length; i++) {
      for (let j = 0; j < datos.length; j++) {
        if (busqueda[i] === datos[j].nombre) {
          datosMostrar.push(datos[j]);
        }
      }
    }
    return datosMostrar;
  };

  //Creamos una funcion que convierte los datos encontrados en un array con estos datos.
  const convertirDatosAComponente = () => {
    let componenteMostrar = [];
    if (inputBuscador.length > 0) {
      componenteMostrar = encontrarDatos(
        filterItems(inputBuscador, mapearDatos()),
        usuarios
      ).map((usuario) => {
        return usuario;
      });
    }
    return componenteMostrar;
  };

  useEffect(() => {
    if (inputBuscador.length > 0) {
      console.log("input escrito");
      setDatosProcesados(convertirDatosAComponente());
    } else if (inputBuscador.length === 0) {
      setDatosProcesados(usuarios);
    }
  }, [inputBuscador, carga, usuarios]);

  //______________________________________________________________________

  let componenteUsuarios;
  //Creamos las tarjetas segun la cantidad de usuarios en la base de datos.
  if (error) {
    componenteUsuarios = [<h2>Error: {error.message}</h2>];
  } else if (!carga) {
    componenteUsuarios = [<div className="lista-usuarios__div--container-cargando-procesando"><div className='cargando-animacion' id='cargando animacion'></div><div className="lista-usuarios__div--mensaje">Cargando...</div></div>];
  } else if (carga) {
    // console.log("mapear", mapearDatos());
    if (datosProcesados) {
      componenteUsuarios = datosProcesados.map((usuario) => {
        return <UsuarioTarjeta usuario={usuario} />;
      });
    } else {
      componenteUsuarios = [<div className="lista-usuarios__div--container-cargando-procesando"><div className='cargando-animacion' id='cargando animacion'></div><div className="lista-usuarios__div--mensaje">Procesando...</div></div>];
    }
  }

  const [desde, setDesde] = useState(0);
  const [hasta, setHasta] = useState(datosPorPagina);
  const [render, setRender] = useState(componenteUsuarios.slice(desde, hasta));

  //Funcion para siguiente pagina.
  const next = () => {
    if (hasta < componenteUsuarios.length) {
      setDesde(desde + datosPorPagina);
      setHasta(hasta + datosPorPagina);
    }
  };

  //Funcion para pagina previa.
  const prev = () => {
    if (desde > 0) {
      setDesde(desde - datosPorPagina);
      setHasta(hasta - datosPorPagina);
    }
  };

  //Cambiamos para que set render tome datos solo cuando cargan de la api, y hacemos que cuando desde y hasta cambien se ejecute el efecto.
  useEffect(() => {
    if (carga) {
      setRender(componenteUsuarios.slice(desde, hasta));
    }
  }, [carga, desde, hasta, usuarios]);

  //Creamos un efecto que va a verificar cuando quitar y mantener los botones de pasar pagina ejecutados por prev y next que son las funciones especificas de pasar pagina.
  useEffect(() => {
    //Corroboramos si boton prev esta en la primera pagina lo quitamos sino aparece.
    if (desde === 0) {
      setBotonPrev(false);
    } else if (desde !== 0) {
      setBotonPrev(true);
    }

    //Corroboramos si boton next esta en la ultima pagina lo quitamos sino aparece.
    if (hasta >= componenteUsuarios.length) {
      setBotonNext(false);
    } else if (hasta < componenteUsuarios.length) {
      setBotonNext(true);
    }
  }, [prev, next]);

  return (
    <div className="lista-usuarios__div--container">
      {/* buscador */}
      <div className="lista-usuarios__div--container-buscador">
        <div className="lista-usuarios__div--input-lupa">
          {inputBuscador.length <= 0 ? (<img src={lupaBuscador} alt="lupa"/>):("")}
          <input value={inputBuscador} onChange={handleInputChangeBuscador} />
        </div>
        <button onClick={() => setInputBuscador("")}>Limpiar</button>
      </div>


      {pantallaChica ? (
                render
            ):(
                <>
                    <div className="lista-usuarios__div--seccion-desktop">
                        <div  className="lista-usuarios__div--barra-descripcion">
                            <p className="lista-usuarios__p--campos lista-usuarios__p--campos-usuario">Usuario</p>
                            <p className="lista-usuarios__p--campos lista-usuarios__p--campos-nombre">Nombre</p>
                            <p className="lista-usuarios__p--campos lista-usuarios__p--campos-tipo">Tipo</p>
                            <p className="lista-usuarios__p--campos lista-usuarios__p--campos-email">Email</p>
                            <p className="lista-usuarios__p--campos lista-usuarios__p--campos-vencimiento">Vencimiento</p>
                            <div className="lista-usuarios__p--campos lista-usuarios__p--campos-cofre"><img src={cofre} alt="cofre"/></div>
                            <p className="lista-usuarios__p--campos lista-usuarios__p--campos-estado">Estado</p>
                            <p className="lista-usuarios__p--campos lista-usuarios__p--campos-tipo-cuenta">Tipo de cuenta</p>
                        </div>
                        <div className="lista-usuarios__div--usuarios-lista">
                          {render}
                        </div>
                    </div>
                </>
            )}

      {/* lista de usuarios
      <div className="lista-usuarios__div--lista">{render}</div> */}
      {/* paquete adelante atras y agregar usuario */}
      <div className="lista-usuarios__div--paquete-pasar-pagina-agregar-usuario">
        <div className="lista-usuarios__div--paquete-container-botones">
          {botonPrev ? <button className="lista-usuarios__button--prev" onClick={() => prev()}>Anterior</button> : ""}
        </div>
        <div className="lista-usuarios__div--paquete-container-botones">
          {/* agregar usuario */}
          <button className="lista-usuarios__div--agregar-usuario" onClick={() => setAgregarUsuarioEstado(true)}>
            <div className="lista-usuarios__div--paquete-icono-mas">
              <p className="lista-usuarios__p--signo-mas">+</p>
              <img src={agregarUsuarioIcono} alt="agregar-usuario"/>
            </div>
            <p className="lista-usuarios__p--agregar-usuario-mensaje">Agregar usuario</p>
          </button>
        </div>
        <div  className="lista-usuarios__div--paquete-container-botones">
          {botonNext ? <button className="lista-usuarios__button--next" onClick={() => next()}>Siguiente</button> : ""}
        </div>
      </div>

      {agregarUsuarioEstado ? (
        <AgregarUsuario setAgregarUsuarioEstado={setAgregarUsuarioEstado} />
      ) : (
        ""
      )}

    </div>
  );
};

export default ListaUsuarios;


//Funcion que toma el tamaño de la pantalla en todo momento.
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
