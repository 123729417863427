import { useEffect, useState } from 'react';

//Paginas
import Index from './pages/Index';
import UsuariosAdmin from './pages/UsuariosAdmin';
import Administrador from './pages/Administrador';
import Metricas from './pages/Metricas';

//Componentes
import NavBar from './components/NavBar/NavBar';
import Ensayos from './components/Ensayos';

//Importacion librerias
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { linkBackend } from './lib/linkBackend';

//importacion Componente de proteccion de rutas
import { ProtectedRoute } from './components/ProtectedRoute';

//Redux
import { Provider } from 'react-redux';
import store from './store';
import CargaInicial from './pages/CargaInicial';





function App() {
  const [ usuario, setUsuario ] = useState(null);
  const [ loginIn, setLoginIn ] = useState(false);
  const [ cargaPagina, setcargaPagina ] = useState(true);


  useEffect(() => {
    fetch(linkBackend('/api/login'), {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('Token')
      }
      })
      .then(res => res.json())
      .then(res => {if(res.mensaje !== "Acceso denegado" && res.mensaje !== "Token expirado o incorrecto"){
        setUsuario(res);
        setcargaPagina(false);
      }else{
        setcargaPagina(false);
      }})
      .catch(error => console.error(error));
  }, [loginIn]);


  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<CargaInicial usuario={usuario}/>}/>
          {/* Ruta login administrador */}
          <Route path='/login' element={<Index usuario={usuario} setLoginIn={setLoginIn} loginIn={loginIn} cargaPagina={cargaPagina}/>}/>
          {/* Ensayos */}
          <Route path='/ensayo' element={<Ensayos/>}/>

          {/* Rutas protegidas */}
          <Route path='/home/*' element={<ProtectedRoute isAllowed={!!usuario} redirecTo='/'>
            <NavBar setUsuario={setUsuario} usuario={usuario}/>
          </ProtectedRoute>}>

            <Route path="usuarios" element={<ProtectedRoute isAllowed={!!usuario} redirecTo='/'>
                <UsuariosAdmin/>
            </ProtectedRoute>}/>

            <Route path="administrador" element={<ProtectedRoute isAllowed={!!usuario} redirecTo='/'>
                <Administrador/>
            </ProtectedRoute>}/>

            <Route path="metricas" element={<ProtectedRoute isAllowed={!!usuario} redirecTo='/'>
                <Metricas/>
            </ProtectedRoute>}/>
          </Route>

        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
