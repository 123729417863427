import React from 'react'

const TarjetaJuegos = (props) => {
    const { abierto, nombre, terminado, derecha, izquierda } = props;
    return (
        <div className='tarjeta-juegos__div--container'>
            <div className='tarjeta-juegos__div--container-abierto' style={{background: izquierda}}>
                <p>Abierto</p>
                <p className='tarjeta-juegos__p--numero'>{abierto}</p>
            </div>
            <div className='tarjeta-juegos__div--container-nombre'>
                <p>{nombre}</p>
            </div>
            <div className='tarjeta-juegos__div--container-terminado' style={{background: derecha}}>
                <p>Terminado</p>
                <p className='tarjeta-juegos__p--numero'>{terminado}</p>
            </div>
        </div>
    )
}

export default TarjetaJuegos