import React from 'react'
import { NavLink } from 'react-router-dom';

//Imagenes
import cerrarSesionImg from '../../img/Navbar/cerrar-sesion.png'

const NavBarDesktop = (props) => {
    const { usuario, cerrarSesion } = props;


    return (
        <div className='nav-bar-desktop__div--container'>
            <div className='nav-bar-desktop__div--fondo-animado'></div>
            <div className='nav-bar-desktop__div--paquete-opciones'>
                <div className='nav-bar-desktop__div--opciones-paginas'>
                    <NavLink className='nav-bar-desktop__button--botones nav-bar-desktop__button--botones-usuarios' to='/home/usuarios'>
                        Usuarios
                    </NavLink>
                    <NavLink className='nav-bar-desktop__button--botones nav-bar-desktop__button--botones-administrador' to='/home/administrador'>
                        Administrador
                    </NavLink>
                    <NavLink className='nav-bar-desktop__button--botones nav-bar-desktop__button--botones-metricas' to='/home/metricas'>
                        Metricas
                    </NavLink>
                </div>

                <div className='nav-bar-desktop__div--nombre-cerrar-sesion'>
                    <p>{usuario.usuario}</p>
                    <button onClick={() => cerrarSesion()}>
                        <img src={cerrarSesionImg} alt='cerrar-sesion'/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default NavBarDesktop