import React, { useEffect, useState } from 'react';

//Librerias
import Swal from 'sweetalert2';
import { linkBackend } from '../../../lib/linkBackend';

//Imagenes
import flechaDerecha from '../../../img/usuarios/flecha-derecha.png';
import flechaIzquierda from '../../../img/usuarios/flecha-izquierda.png';



//Funciones globales
import { borrarAcceso } from '../../../lib/funcionesGlobales';
import ActualizarUsuario from '../ActualizarUsuario';
import ActualizarContrasenaUsuario from '../ActualizarContrasenaUsuario';

const UsuarioTarjetaDesktop = (props) => {
    const [ activo, setactivo ] = useState(false);
    const [ masivo, setMasivo ] = useState(false);
    const [ componenteActualizar, setComponenteActualizar ] = useState(false);
    const [ componenteActualizarcontrasena, setComponenteActualizarContrasena ] = useState(false);
    const [ opciones, setOpciones ] = useState(false);
    const { _id, usuario, nombre, email, tipo, vencimiento, puntosSeguimiento, cuentaMasiva, cuentaActiva } = props;


    //Funcion que cambia el estado de la cuenta a activa o inactiva.
    const cambiarEstadoDeCuenta = async () => {
        try {
            let config = {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('Token')
                },body: JSON.stringify({
                    _id: _id
                })
            };

            fetch(linkBackend("/api/login-usuarios/activa-cuenta"), config)
            .then(response => response.json())
            .then((data) => {
                if(data.mensaje === "Cambiada a cuenta inactiva exitosamente."){
                    Swal.fire(
                        'Hecho',
                        'Cuenta desactivada exitosamente.',
                        'success');
                }else if(data.mensaje === "Cambiada a cuenta activa exitosamente."){
                    Swal.fire(
                        'Hecho',
                        'Cuenta activada con exito.',
                        'success');
                }else{
                    Swal.fire(
                        'Error',
                        'Error al cambiar el estado de la cuenta.',
                        'error');
                }
            })
        } catch (error) {
            console.error(error,"Error al cambiar el estado de la cuenta.")
        };
    };

    //Funcion que cambia el estado de la cuenta a activa o inactiva.
    const cambiarTipoDeCuenta = async () => {
        try {
            let config = {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('Token')
                },body: JSON.stringify({
                    _id: _id
                })
            };

            fetch(linkBackend("/api/login-usuarios/cuenta-masiva"), config)
            .then(response => response.json())
            .then((data) => {
                if(data.mensaje === "Cambiada a cuenta masiva exitosamente."){
                    Swal.fire(
                        'Hecho',
                        'Cuenta cambiada a cuenta masiva exitosamente.',
                        'success');
                }else if(data.mensaje === "Cambiada a cuenta individual exitosamente."){
                    Swal.fire(
                        'Hecho',
                        'Cuenta cambiada a individual exitosamente.',
                        'success');
                }else{
                    Swal.fire(
                        'Error',
                        'Error al cambiar el tipo de la cuenta.',
                        'error');
                }
            })
        } catch (error) {
            console.error(error,"Error al cambiar el tipo de la cuenta.")
        };
    };

    useEffect(() => {
        if(cuentaActiva){
            setactivo(true);
        }else if(!cuentaActiva){
            setactivo(false);
        }
    }, [cambiarEstadoDeCuenta]);

    useEffect(() => {
        if(cuentaMasiva){
            setMasivo(true);
        }else if(!cuentaMasiva){
            setMasivo(false);
        }
    }, [cambiarTipoDeCuenta]);

    return (
        <>
            <div className='usuario-tarjeta-desktop__div--container'>
                <p className='usuario-tarjeta-desktop__p--usuario'>{usuario}</p>
                <p className='usuario-tarjeta-desktop__p--nombre'>{nombre}</p>
                <p className='usuario-tarjeta-desktop__p--tipo'>{tipo}</p>
                <p className='usuario-tarjeta-desktop__p--email'>{email}</p>
                <p className='usuario-tarjeta-desktop__p--vencimiento'>{vencimiento}</p>
                <p className='usuario-tarjeta-desktop__p--cofre'>{puntosSeguimiento}</p>
                {activo ? (<button className='usuario-tarjeta-desktop__p--estado' style={{backgroundColor: "#00BB07"}} onClick={() => cambiarEstadoDeCuenta()}>Activa</button>):(<button className='usuario-tarjeta-desktop__p--estado' style={{backgroundColor: "#D60000"}} onClick={() => cambiarEstadoDeCuenta()}>Inactiva</button>)}
                {masivo ? (<button className='usuario-tarjeta-desktop__p--tipo-cuenta' style={{backgroundColor: "#00CED1"}} onClick={() => cambiarTipoDeCuenta()}>Masiva</button>):(<button className='usuario-tarjeta-desktop__p--tipo-cuenta' style={{backgroundColor: "#FFA500"}} onClick={() => cambiarTipoDeCuenta()}>Individual</button>)}

                {/* Opciones */}
                <div className='usuario-tarjeta-desktop__div-container-opciones'>
                    {opciones ? (<button className='usuario-tarjeta-desktop__button-opciones' onClick={() => setOpciones(false)}><img src={flechaDerecha} alt='flecha-derecha'/></button>):(<button className='usuario-tarjeta-desktop__button-opciones' onClick={() => setOpciones(true)}><img src={flechaIzquierda} alt='flecha-izquierda'/></button>)}

                    <div style={{width: opciones ? ("914px"):("0"), borderLeft : opciones ? ("2px solid white"):("none")}} className='usuario-tarjeta-desktop__div--container-opciones'>
                        <button onClick={() => setComponenteActualizar(true)}>Actualizar</button>
                        <button onClick={() => setComponenteActualizarContrasena(true)}>Cambio contraseña</button>
                        <button onClick={() => borrarAcceso(_id, "¿Quieres borrar este usuario?", linkBackend("/api/login-usuarios/eliminar"), "Usuario borrado con exito", "Error al borrar usuario", Swal)}>Borrar</button>
                    </div>
                </div>


                {componenteActualizar ? (<ActualizarUsuario usuario={props} setComponenteActualizar={setComponenteActualizar}/>):("")}
                {componenteActualizarcontrasena ? (<ActualizarContrasenaUsuario usuario={props} setComponenteActualizarContrasena={setComponenteActualizarContrasena}/>):("")}
            </div>
        </>

    )
}

export default UsuarioTarjetaDesktop