import React, { useEffect, useState } from 'react'
import { linkBackend } from '../lib/linkBackend';
import AdministradorTarjeta from '../components/Administradores/AdministradorTarjeta';
import AgregarAdministrador from '../components/Administradores/AgregarAdministrador';
import agregarUsuarioIcono from "../img/usuarios/agregar-usuario.png";

const Administrador = () => {
    const [ carga, setCarga ] = useState("");
    const [ administradores, setAdministradores ] = useState("");
    const [ error, setError ] = useState("");
    const [ agregarAdministrador, setAgregarAdministrador ] = useState(false);


    useEffect(() => {
        let intervalo = setInterval(() => {
        fetch( linkBackend('/api/login/accesos'),{
            headers: {
                Authorization: localStorage.getItem('Token')
            }
        })
        .then(response => response.json())
        .then((data) => {
            setCarga(true);
            setAdministradores(data);
        },(error) => {
            setCarga(true);
            setError(error);
        })}, 1000)

        return () => {
            clearInterval(intervalo);
        }
    }, []);


    let componenteAdministradores;
    //Creamos las tarjetas segun la cantidad de usuarios en la base de datos.
    if(error){
        componenteAdministradores = [<h2>Error: {error.message}</h2>];
    }else if(!carga){
        componenteAdministradores =  [<div className="lista-usuarios__div--container-cargando-procesando"><div className='cargando-animacion' id='cargando animacion'></div><div className="lista-usuarios__div--mensaje">Cargando...</div></div>];
    }else if(carga){
        componenteAdministradores = administradores.map((administrador) => {
            return <AdministradorTarjeta administrador={administrador} />
        });
    };





    return (
        <div className='administrador__div--container'>
            <h4>Administradores</h4>
            <div className='administrador__div--container-info'>
                {componenteAdministradores}
            </div>
            {agregarAdministrador ? (<AgregarAdministrador setAgregarAdministrador={setAgregarAdministrador}/>):("")}
            {/* agregar usuario */}
            <button className="administrador__div--agregar-usuario" onClick={() => setAgregarAdministrador(true)}>
                <div className="administrador__div--paquete-icono-mas">
                    <p className="administrador__p--signo-mas">+</p>
                    <img src={agregarUsuarioIcono} alt="agregar-usuario"/>
                </div>
                    <p className="administrador__p--agregar-usuario-mensaje">Agregar administrador</p>
            </button>
        </div>
    )
}

export default Administrador