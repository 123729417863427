import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

//Imagenes
import cofre from '../../../img/usuarios/cofre.png';
import actualizarContrasena from '../../../img/usuarios/actualizar-contrasena.png';
import actualizarUsuario from '../../../img/usuarios/actualizar-usuario.png';
import borrarUsuario from '../../../img/usuarios/borrar-usuario.png';

//Librerias
import Swal from 'sweetalert2';
import { linkBackend } from '../../../lib/linkBackend';

//Funciones globales
import { borrarAcceso } from '../../../lib/funcionesGlobales';
import ActualizarUsuario from '../ActualizarUsuario';
import ActualizarContrasenaUsuario from '../ActualizarContrasenaUsuario';

const UsuarioTarjetaMovil = (props) => {
    const [ activo, setactivo ] = useState(false);
    const [ masivo, setMasivo ] = useState(false);
    const [ componenteActualizar, setComponenteActualizar ] = useState(false);
    const [ componenteActualizarcontrasena, setComponenteActualizarContrasena ] = useState(false);
    const [ opciones, setOpciones ] = useState(false);
    const [ opcionesCerrar, setOpcionesCerrar ] = useState(false);
    const { _id, usuario, nombre, email, tipo, vencimiento, puntosSeguimiento, cuentaMasiva, cuentaActiva } = props;

    //UseRef para css transitions
    const nodeRef = useRef(null);


    //Funcion que cambia el estado de la cuenta a activa o inactiva.
    const cambiarEstadoDeCuenta = async () => {
        try {
            let config = {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('Token')
                },body: JSON.stringify({
                    _id: _id
                })
            };

            fetch(linkBackend("/api/login-usuarios/activa-cuenta"), config)
            .then(response => response.json())
            .then((data) => {
                if(data.mensaje === "Cambiada a cuenta inactiva exitosamente."){
                    Swal.fire(
                        'Hecho',
                        'Cuenta desactivada exitosamente.',
                        'success');
                }else if(data.mensaje === "Cambiada a cuenta activa exitosamente."){
                    Swal.fire(
                        'Hecho',
                        'Cuenta activada con exito.',
                        'success');
                }else{
                    Swal.fire(
                        'Error',
                        'Error al cambiar el estado de la cuenta.',
                        'error');
                }
            })
        } catch (error) {
            console.error(error,"Error al cambiar el estado de la cuenta.")
        };
    };

    //Funcion que cambia el estado de la cuenta a activa o inactiva.
    const cambiarTipoDeCuenta = async () => {
        try {
            let config = {
                method: 'PUT',
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('Token')
                },body: JSON.stringify({
                    _id: _id
                })
            };

            fetch(linkBackend("/api/login-usuarios/cuenta-masiva"), config)
            .then(response => response.json())
            .then((data) => {
                if(data.mensaje === "Cambiada a cuenta masiva exitosamente."){
                    Swal.fire(
                        'Hecho',
                        'Cuenta cambiada a cuenta masiva exitosamente.',
                        'success');
                }else if(data.mensaje === "Cambiada a cuenta individual exitosamente."){
                    Swal.fire(
                        'Hecho',
                        'Cuenta cambiada a individual exitosamente.',
                        'success');
                }else{
                    Swal.fire(
                        'Error',
                        'Error al cambiar el tipo de la cuenta.',
                        'error');
                }
            })
        } catch (error) {
            console.error(error,"Error al cambiar el tipo de la cuenta.")
        };
    };

    useEffect(() => {
        if(cuentaActiva){
            setactivo(true);
        }else if(!cuentaActiva){
            setactivo(false);
        }
    }, [cambiarEstadoDeCuenta]);

    useEffect(() => {
        if(cuentaMasiva){
            setMasivo(true);
        }else if(!cuentaMasiva){
            setMasivo(false);
        }
    }, [cambiarTipoDeCuenta]);

    const procesarOpciones = () => {
        setOpciones(!opciones);
    }


    return (
        <>
            <div className='usuario-tarjeta-movil__div--container' style={{backgroundColor: cuentaMasiva ? ("#00CED1"):("#FFA500")}}>
                <CSSTransition
                    nodeRef={nodeRef}
                    in={opciones}
                    timeout={300}
                    classNames="transicion-opciones"
                    onEnter={() => setOpciones(true)}
                    onExited={() => setOpcionesCerrar(true)}
                >
                    {opciones ? (
                        <div ref={nodeRef}className='usuario-tarjeta-movil__div--botones-opciones'>
                            <h4>{usuario}</h4>
                            <button onClick={() => setComponenteActualizar(true)}><img src={actualizarUsuario} alt='actualizar usuario'/>Actualizar usuario</button>
                            <button onClick={() => setComponenteActualizarContrasena(true)}><img src={actualizarContrasena} alt='cambio-contrasena'/>Cambiar contraseña</button>
                            <button onClick={() => borrarAcceso(_id, "¿Quieres borrar este usuario?", linkBackend("/api/login-usuarios/eliminar"), "Usuario borrado con exito", "Error al borrar usuario", Swal)}><img src={borrarUsuario} alt='borrar-usuario'/>Borrar usuario</button>
                        </div>
                            ):(
                        <div ref={nodeRef}className='usuario-tarjeta-movil__div--informacion-grid'>
                            <div>
                                <h5 className='usuario-tarjeta-movil__h5--usuario'>Usuario</h5>
                                <p className='usuario-tarjeta-movil__p--usuario'>{usuario}</p>
                            </div>
                            <div>
                                <h5 className='usuario-tarjeta-movil__h5--nombre'>Nombre</h5>
                                <p className='usuario-tarjeta-movil__p--nombre'>{nombre}</p>
                            </div>
                            <div>
                                <h5 className='usuario-tarjeta-movil__h5--tipo'>Tipo</h5>
                                <p className='usuario-tarjeta-movil__p--tipo'>{tipo}</p>
                            </div>
                            <div>
                                <h5 className='usuario-tarjeta-movil__h5--email'>Email</h5>
                                <p className='usuario-tarjeta-movil__p--email'>{email}</p>
                            </div>
                            <div>
                                <h5 className='usuario-tarjeta-movil__h5--vencimiento'>Vencimiento</h5>
                                <p className='usuario-tarjeta-movil__p--vencimiento'>{vencimiento}</p>
                            </div>
                            <div>
                                <h5 className='usuario-tarjeta-movil__h5--cofre'><img className='usuario-tarjeta-movil__img--cofre' src={cofre} alt='cofre'/></h5>
                                <p className='usuario-tarjeta-movil__p--cofre'>{puntosSeguimiento}</p>
                            </div>
                            <div>
                                <h5 className='usuario-tarjeta-movil__h5--estado'>Estado</h5>
                                <p className='usuario-tarjeta-movil__p--estado'>
                                    {activo ? (<button style={{backgroundColor: "#00BB07"}} onClick={() => cambiarEstadoDeCuenta()}>Activa</button>):(<button style={{backgroundColor: "#D60000"}} onClick={() => cambiarEstadoDeCuenta()}>Inactiva</button>)}
                                </p>
                            </div>
                            <div>
                                <h5 className='usuario-tarjeta-movil__h5--cantidad'>Tipo de cuenta</h5>
                                <p className='usuario-tarjeta-movil__p--cantidad'>
                                {masivo ? (<button style={{backgroundColor: "#00CED1"}} onClick={() => cambiarTipoDeCuenta()}>Masiva</button>):(<button style={{backgroundColor: "#FFA500"}} onClick={() => cambiarTipoDeCuenta()}>Individual</button>)}
                                </p>
                            </div>
                        </div>
                        )}
                    </CSSTransition>
            <button className='usuario-tarjeta-movil__button--opciones' onClick={() => procesarOpciones()}>{opciones ? ("Cerrar"):("Opciones")}</button>
            {componenteActualizar ? (<ActualizarUsuario usuario={props} setComponenteActualizar={setComponenteActualizar}/>):("")}
            {componenteActualizarcontrasena ? (<ActualizarContrasenaUsuario usuario={props} setComponenteActualizarContrasena={setComponenteActualizarContrasena}/>):("")}
            </div>
        </>

    )
}

export default UsuarioTarjetaMovil